<template>
    <div
        :class="[
            'section',
            { 'section--white': backgroundColor === 'white' },
            { 'section--grey': backgroundColor === 'grey' },
            { 'section--content-left': contentPosition === 'left' },
            {
                'section--content-center': contentPosition === 'center',
            },
            { 'section--content-right': contentPosition === 'right' },
            {
                'section--image-container-top':
                    imageContainerPosition === 'top',
            },
            {
                'section--image-container-bottom':
                    imageContainerPosition === 'bottom',
            },
        ]"
    >
        <div class="section__container">
            <div class="section__content">
                <div class="section__headline">
                    <slot name="headline" />
                </div>
                <div class="section__text">
                    <slot name="text" />
                </div>
                <slot name="button" />
            </div>
            <slot name="media" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        contentPosition: {
            type: String,
            default: 'left',
        },

        backgroundColor: {
            type: String,
            default: 'white',
        },

        imageContainerPosition: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
.section {
    padding: 40px 0;
    overflow: hidden;

    &--white {
        background-color: white;
    }

    &--grey {
        background-color: $light_blue_03;
    }

    @include breakpoint(xs) {
        padding: 80px 0;
    }

    &__container {
        @include container;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    &__content {
        max-width: 750px;
        margin-top: 0;
        text-align: left;

        .section--content-left & {
            order: 2;
            text-align: center;

            @include breakpoint(xs) {
                max-width: 50%;
                padding-right: 80px;
                z-index: 1;
                text-align: left;
            }
        }

        .section--content-center & {
            margin: 0 auto;
            text-align: center;
        }

        .section--content-right & {
            order: 2;
            text-align: center;

            @include breakpoint(xs) {
                width: calc(50% - 15px);
                text-align: left;
            }

            @include breakpoint(sm) {
                width: calc(50% - 30px);
            }
        }

        .section--image-container-top & {
            order: 2;
        }

        .section--image-container-bottom & {
            order: 1;
            margin-bottom: 30px;

            @include breakpoint(xs) {
                margin-bottom: 60px;
            }
        }
    }

    &__image-container {
        text-align: center;
        max-width: 100%;
        position: relative;

        img {
            box-shadow: 0px 0px 30px rgba(54, 65, 75, 0.25);
        }

        .section--content-left & {
            order: 1;
            width: 100%;
            text-align: center;
            border-radius: 3px;
            margin-bottom: 30px;

            @include breakpoint(xs) {
                width: calc(50% - 15px);
                text-align: right;
                order: 2;
                margin-bottom: 0;
            }

            @include breakpoint(sm) {
                width: calc(50% - 30px);
            }
        }

        .section--content-center & {
            width: 100%;
            text-align: center;
        }

        .section--content-right & {
            order: 1;
            width: 100%;
            text-align: center;
            border-radius: 3px;
            margin-bottom: 30px;

            @include breakpoint(xs) {
                width: calc(50% - 15px);
                text-align: left;
                margin-bottom: 0;
            }

            @include breakpoint(sm) {
                width: calc(50% - 30px);
            }
        }

        .section--image-container-top & {
            order: 1;
            margin-bottom: 45px;

            img {
                @include breakpoint(xs) {
                    width: calc(50% - 15px);
                    height: auto;
                }

                @include breakpoint(sm) {
                    width: calc(50% - 30px);
                }
            }
        }

        .section--image-container-bottom & {
            order: 2;
            margin-bottom: -65px;

            @include breakpoint(xs) {
                margin-bottom: -95px;
            }

            img {
                height: auto;
                width: 80%;
                border-radius: 3px 3px 0px 0px;

                @include breakpoint(xs) {
                    height: 230px;
                    width: auto;
                }
            }
        }
    }

    &__headline {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $dark_blue_01;

        @include breakpoint(xs) {
            font-size: 24px;
            line-height: 30px;
        }
    }

    &__text {
        margin-top: 15px;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 22px;
        color: $dark_blue_02;

        @include breakpoint(xs) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__button {
        margin-top: 25px;
        display: inline-block;
        border: 1px solid $red;
        border-radius: 3px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        padding: 6px 12px;
        color: $red;
        transition: 0.3s all;
        cursor: pointer;

        &:hover {
            background-color: $red;
            color: white;
        }
    }
}
</style>
