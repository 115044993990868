<template>
    <div
        :class="[
            'headline',

            { 'headline--left': contentPosition === 'left' },
            {
                'headline--center': contentPosition === 'center',
            },
            { 'headline--right': contentPosition === 'right' },
        ]"
    >
        <div v-if="$slots.icon" class="headline__icon">
            <slot name="icon"></slot>
        </div>
        <slot name="text"></slot>
    </div>
</template>

<script>
export default {
    props: {
        contentPosition: {
            type: String,
            default: 'center',
        },
    },
};
</script>

<style lang="scss" scoped>
.headline {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: $dark_blue_01;

    @include breakpoint(xs) {
        font-size: 22px;
        line-height: 28px;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 24px;
            height: 24px;
            stroke: $red;

            @include breakpoint(xs) {
                width: 30px;
                height: 30px;
            }
        }
    }
}

.headline--left {
    @include breakpoint(xs) {
        justify-content: flex-start;
    }
}

.headline--center {
    @include breakpoint(xs) {
        justify-content: center;
    }
}

.headline--right {
    @include breakpoint(xs) {
        justify-content: flex-end;
    }
}
</style>
