var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'headline',

        { 'headline--left': _vm.contentPosition === 'left' },
        {
            'headline--center': _vm.contentPosition === 'center',
        },
        { 'headline--right': _vm.contentPosition === 'right' },
    ]},[(_vm.$slots.icon)?_c('div',{staticClass:"headline__icon"},[_vm._t("icon")],2):_vm._e(),_vm._v(" "),_vm._t("text")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }