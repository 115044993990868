var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'section',
        { 'section--white': _vm.backgroundColor === 'white' },
        { 'section--grey': _vm.backgroundColor === 'grey' },
        { 'section--content-left': _vm.contentPosition === 'left' },
        {
            'section--content-center': _vm.contentPosition === 'center',
        },
        { 'section--content-right': _vm.contentPosition === 'right' },
        {
            'section--image-container-top':
                _vm.imageContainerPosition === 'top',
        },
        {
            'section--image-container-bottom':
                _vm.imageContainerPosition === 'bottom',
        },
    ]},[_c('div',{staticClass:"section__container"},[_c('div',{staticClass:"section__content"},[_c('div',{staticClass:"section__headline"},[_vm._t("headline")],2),_vm._v(" "),_c('div',{staticClass:"section__text"},[_vm._t("text")],2),_vm._v(" "),_vm._t("button")],2),_vm._v(" "),_vm._t("media")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }