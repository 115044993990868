<template>
    <div class="customers">
        <div class="customers__container">
            <div class="customers__content">
                <section-headline content-position="left">
                    <template slot="icon"><users-icon /></template>
                    <template slot="text">Unsere Kunden</template>
                </section-headline>

                <div class="customers__text">
                    Bei COPPIO sind wir stolz darauf, eine vielfältige
                    Kundenbasis in Deutschland und Österreich zu betreuen.
                    Unsere Karte zeigt die Reichweite unseres Netzwerks – von
                    pulsierenden Städten bis zu idyllischen ländlichen Regionen.
                    Jeder Punkt symbolisiert eine einzigartige Partnerschaft und
                    unser Engagement, maßgeschneiderte Lösungen für die
                    spezifischen Bedürfnisse jedes Kunden zu bieten. Werden Sie
                    Teil unserer Erfolgsgemeinschaft wie folgende Kunden:
                </div>

                <div class="customers__logo-container">
                    <a
                        href="https://www.coppio.app/fotohaus-zacharias"
                        target="_blank"
                        class="customers__logo"
                    >
                        <img
                            src="~/assets/customers/fotohaus-zacharias.png"
                            alt="Referenz: Fotohaus Zacharias"
                        />
                    </a>
                    <a
                        href="https://www.coppio.app/fotofrenzel-muensterplatz"
                        target="_blank"
                        class="customers__logo"
                    >
                        <img
                            src="~/assets/customers/hobby-foto-fotoprofi.png"
                            alt="Referenz: Hobby Foto Fotoprofi"
                        />
                    </a>
                    <a
                        href="https://www.coppio.app/fix-foto"
                        target="_blank"
                        class="customers__logo"
                    >
                        <img
                            src="~/assets/customers/fix-foto-digital.png"
                            alt="Referenz: Fix Foto Digital"
                        />
                    </a>
                    <a
                        href="https://www.coppio.app/lamprechter"
                        target="_blank"
                        class="customers__logo"
                    >
                        <img
                            src="~/assets/customers/lamprechter.png"
                            alt="Referenz: Lamprechter"
                        />
                    </a>
                </div>

                <nuxt-link to="/kontakt" class="customers__button">
                    <arrow-right-icon />Jetzt Kontakt aufnehmen
                </nuxt-link>
            </div>
            <div class="customers__map-container">
                <CustomersMap />
            </div>
        </div>
    </div>
</template>

<script>
import { UsersIcon, ArrowRightIcon } from 'vue-feather-icons';
import SectionHeadline from '~/components/section-headline.vue';
import CustomersMap from '~/components/customers-map.vue';

export default {
    components: {
        SectionHeadline,
        ArrowRightIcon,
        UsersIcon,
        CustomersMap,
    },
};
</script>

<style lang="scss" scoped>
.customers {
    background-color: white;
    padding: 40px 0;

    @include breakpoint(xs) {
        padding: 80px 0;
    }

    &__container {
        @include container;

        @include breakpoint(xs) {
            gap: 100px;
            display: flex;
            align-items: center;
            gap: 100px;
        }
    }

    &__content {
        width: 100%;
        text-align: center;

        @include breakpoint(xs) {
            text-align: left;
            width: 45%;
        }
    }

    &__text {
        margin: 20px auto 0;
        font-weight: normal;
        font-size: 15px;
        line-height: 22px;

        color: $dark_blue_02;

        @include breakpoint(xs) {
            margin: 30px auto 0;
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__logo {
        position: relative;
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            transition: 0.3s all;
            width: 100%;
            height: auto;
            opacity: 0.4;
            filter: grayscale(100%);
        }

        &:hover {
            img {
                opacity: 1;
                filter: grayscale(0);
            }
        }

        &-container {
            display: grid;
            justify-content: center;
            margin: 30px 0;
            row-gap: 20px;
            column-gap: 30px;
            grid-template-columns: repeat(2, 90px);
            grid-template-rows: auto;

            @include breakpoint(xs) {
                justify-content: flex-start;
                grid-template-columns: repeat(2, 110px);
                column-gap: 80px;
                row-gap: 20px;
            }

            @include breakpoint(md) {
                grid-template-columns: repeat(2, 120px);
            }
        }
    }

    &__button {
        display: inline-flex;
        align-items: center;
        gap: 6px;
        border: 1px solid $red;
        border-radius: 3px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        padding: 6px 12px;
        color: $red;
        transition: 0.3s all;
        cursor: pointer;

        svg {
            stroke: $red;
            width: 15px;
            height: 15px;
            stroke-width: 3px;
        }

        &:hover {
            background-color: $red;
            color: white;

            svg {
                stroke: white;
            }
        }
    }

    &__map-container {
        width: 100%;
        text-align: center;
        margin-top: 30px;

        @include breakpoint(xs) {
            margin-top: 0;
            width: 55%;
        }

        svg {
            margin-top: 30px;
            width: 70%;
            height: auto;

            @include breakpoint(xs) {
                margin-top: 0;
                width: 100%;
            }
        }
    }
}
</style>
